import { RootReducer } from "../../reducers";
import { formatDate } from "utils/formatDate";
import { DATE_FORMATS } from "../../constants";
import { createSelector } from "reselect";
import {
  RegionS3StorageMetricsReport,
  S3StorageMetricsReport,
  S3StorageMetricsReportFormatted,
  S3StorageTopUsersReport,
  TableS3StorageTopUsersReport,
  TableTotalS3StorageTopUsersReport
} from "./types";
import { formatSize } from "utils/formatSize";
import { draftS3MetricsReport, draftS3report } from "./drafts";

export const s3StorageMetricsReportSelector = (
  state: RootReducer
  // ): S3StorageMetricsReport | null => draftS3MetricsReport;
): S3StorageMetricsReport | null => state.s3_storage.s3StorageMetricsReport;

export const s3StorageTopUsersReportSelector = (
  state: RootReducer
  // ): S3StorageTopUsersReport | null => draftS3report;
): S3StorageTopUsersReport | null => state.s3_storage.s3StorageTopUsersReport;

// selectors for table top users
export const tableS3StorageTopUsersReportSelector = createSelector(
  [s3StorageTopUsersReportSelector],
  (storageReport): TableS3StorageTopUsersReport[] | null => {
    if (!storageReport) {
      return null;
    }
    return storageReport.storage_usage.map((usage) => ({
      id: usage.project_id,
      project_name: usage.project_name,
      user_id: usage.user_id,
      user_name: usage.user_name,
      email: usage.email,
      region: usage.region,
      organization_id: usage.organization_id,
      storage_size: formatSize(Number(usage.storage_size)),
      storage_objects: usage.storage_objects.toLocaleString(),
      storage_buckets: usage.storage_buckets
    }));
  }
);

export const tableTotalS3StorageTopUsersReportSelector = createSelector(
  [s3StorageTopUsersReportSelector],
  (storageReport): TableTotalS3StorageTopUsersReport | null => {
    if (!storageReport) {
      return null;
    }
    return {
      id: "",
      project_name: "Total",
      user_id: "",
      user_name: "",
      email: "",
      region: "",
      organization_id: "",
      storage_size: formatSize(Number(storageReport.total_size)),
      storage_objects: storageReport.total_objects.toLocaleString(),
      storage_buckets: storageReport.total_buckets.toLocaleString()
    };
  }
);

// Selector to get the region metrics overview
export const regionS3StorageMetricsReportSelector = createSelector(
  [s3StorageMetricsReportSelector],
  (storageReport): RegionS3StorageMetricsReport[] | null =>
    storageReport
      ? Object.entries(storageReport.region_storage_overview).map(
          ([region, data]) => ({
            region,
            region_total_size: formatSize(data.region_total_size),
            region_total_objects: data.region_total_objects,
            region_total_buckets: data.region_total_buckets
          })
        )
      : null
);

export const isS3StorageMetricsReportLoadingSelector = (
  state: RootReducer
): boolean => state.s3_storage.isS3StorageMetricsReportLoading;

export const isS3StorageTopUsersReportLoadingSelector = (
  state: RootReducer
): boolean => state.s3_storage.isS3StorageTopUsersReportLoading;

export const isS3StorageReportCacheUpdatingSelector = (
  state: RootReducer
): boolean => state.s3_storage.isS3StorageCacheUpdating;

export const s3StorageCacheDateSelector = createSelector(
  [s3StorageTopUsersReportSelector],
  (storageReport): string | null =>
    storageReport
      ? formatDate(new Date(storageReport.cached_at), DATE_FORMATS.DATETIME)
      : null
);

export const s3StorageMetricsReportFormattedSelector = createSelector(
  [s3StorageMetricsReportSelector],
  (storageReport): S3StorageMetricsReportFormatted | null => {
    // If the storage report is not available, return null
    if (!storageReport) {
      return null;
    }

    // Extract the data directly from the storage report
    const {
      regions,
      total_size,
      total_objects,
      total_buckets,
      region_storage_overview
    } = storageReport;

    // Initialize statistics objects
    const statistics_by_size: { [key: string]: number } = {};
    const statistics_by_objects: { [key: string]: number } = {};
    const statistics_by_buckets: { [key: string]: number } = {};

    // Function to shorten region names
    const shortenRegionName = (region: string) => {
      if (region === "upper-austria") {
        return "Upper-AU";
      }
      if (region === "central-switzerland") {
        return "Central-SW";
      }
      if (region === "eastern-switzerland") {
        return "Eastern-SW";
      }
      if (region === "vienna") {
        return "Vienna";
      }
      return region; // Else, return the region as is
    };

    // Shorten the region names
    const shortenedRegions = regions.map(shortenRegionName);

    // Iterate over each region and gather statistics
    Object.entries(region_storage_overview).forEach(([region, data]) => {
      // Use shortened region names for statistics
      const shortenedRegion = shortenRegionName(region);

      statistics_by_size[shortenedRegion] = data.region_total_size;
      statistics_by_objects[shortenedRegion] = data.region_total_objects;
      statistics_by_buckets[shortenedRegion] = data.region_total_buckets;
    });

    // Return the aggregated metrics with shortened region names in statistics
    return {
      regions: shortenedRegions,
      total_size,
      total_objects,
      total_buckets,
      statistics_by_size,
      statistics_by_objects,
      statistics_by_buckets
    };
  }
);
