export enum S3StorageReportAttribute {
  Size = "size",
  Objects = "objects",
  Buckets = "buckets"
}

export const S3_GET_REPORT_ATTR_OPTIONS = [
  { value: "size", label: "Size" },
  { value: "objects", label: "Objects" },
  { value: "buckets", label: "Buckets" }
];

export const S3_GET_REPORT_TOP_OPTIONS = [
  { value: "10", label: "10" },
  { value: "50", label: "50" },
  { value: "100", label: "100" }
  // { value: "500", label: "500" },
  // { value: "", label: "All" }
];

export type S3StorageTopUsersReport = {
  regions: string[];
  cached_at: string;
  total_size: number;
  total_objects: number;
  total_buckets: number;
  storage_usage: {
    project_id: string;
    project_name: string;
    user_id: string;
    user_name: string;
    email: string;
    region: string;
    organization_id: string;
    storage_size: number;
    storage_objects: number;
    storage_buckets: number;
  }[];
};

export type TableS3StorageTopUsersReport = {
  id: string; //project_id
  project_name: string;
  user_id: string;
  user_name: string;
  email: string;
  region: string;
  organization_id: string;
  storage_size: string;
  storage_objects: string;
  storage_buckets: number;
};

export type TableTotalS3StorageTopUsersReport = {
  id: ""; // __string
  project_name: string;
  user_id: string;
  user_name: string;
  email: string;
  region: string;
  organization_id: string;
  storage_size: string;
  storage_objects: string;
  storage_buckets: string;
};

export type GetS3StorageTopUsersReportParams = {
  regions?: string;
  top?: number;
  attribute?: S3StorageReportAttribute;
  pollingId?: string;
};

export type GetS3StorageTopUsersReportResponse = S3StorageTopUsersReport;

export type UpdateCacheS3StorageReportResponse = { message: string };

export type S3StorageMetricsReport = {
  regions: string[];
  cached_at: string;
  total_size: number;
  total_objects: number;
  total_buckets: number;
  region_storage_overview: {
    [region: string]: {
      region: string;
      region_total_size: number;
      region_total_objects: number;
      region_total_buckets: number;
    };
  };
};

export type GetS3StorageMetricsReportParams = {
  pollingId?: string;
};

export type GetS3StorageMetricsReportResponse = S3StorageMetricsReport;

export type S3StorageMetricsReportFormatted = {
  regions: string[];
  total_size: number;
  total_objects: number;
  total_buckets: number;
  statistics_by_size: { [key: string]: number };
  statistics_by_objects: { [key: string]: number };
  statistics_by_buckets: { [key: string]: number };
};

export type RegionS3StorageMetricsReport = {
  region: string;
  region_total_size: string;
  region_total_objects: number;
  region_total_buckets: number;
};
