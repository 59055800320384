import { ChangeEvent, MouseEvent, ReactNode } from "react";

export enum TABLE_SORTING_TYPES {
  STRING,
  NUMBER,
  DATE,
  SIZE
}

export type TableColumn<T> = {
  key: keyof T;
  label: string;
  width?: string;
  isCentered?: boolean;
  sortingType?: TABLE_SORTING_TYPES;
};

export type TableRowActionsMenuItem<T> = {
  label: string;
  handler: (id: string) => void;
  isDisabled?: (row: T) => boolean;
};

export type TableMultiActionsItem<T> = {
  label: string;
  handler: (ids: string[]) => void;
  isDisabled?: (row: T) => boolean;
  icon: ReactNode;
};

export type TableProps<T> = {
  title?: string;
  disabledRows?: T[];
  rows: T[];
  columns: TableColumn<T>[];
  totalRow?: { [key in keyof T]: string };
  actions?: TableRowActionsMenuItem<T>[];
  isSelectingEnabled?: boolean;
  multiActions?: TableMultiActionsItem<T>[];
  tabs?: ReactNode;
  toolbarItems?: ReactNode;
  isLoading?: boolean;
  itemLink?: {
    column: keyof T;
    getURL: (id: string) => string | undefined;
    isEnabled?: (item: T) => boolean;
  };
  itemWithIcons?: {
    withText: boolean;
    column: keyof T;
    getIcon: (row: T, columnKey: keyof T) => React.ReactElement;
    // tooltipText: (value: string) => string;
    tooltipText: (row: T) => string;
  }[];
  isSearchEnabled?: boolean;
  customItem?: ReactNode;
  searchLabel?: string;
  isSortingEnabled?: boolean;
  isServerSortingEnabled?: boolean;
  isPaginationEnabled?: boolean;
  page?: number;
  rowsPerPage?: number;
  count?: number;
  onServerSort?: (
    // event: MouseEvent | null,
    column: keyof T,
    order: ORDERS
  ) => void;
  onChangePage?: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onChangeRowsPerPage?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  searchString?: string;
  onChangeSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  resetCheckboxes?: boolean;
  onResetCheckboxes?: () => void;
};

export enum ORDERS {
  ASC = "asc",
  DESC = "desc"
}

type IconButtonColor =
  | "inherit"
  | "default"
  | "info"
  | "error"
  | "primary"
  | "secondary"
  | "success"
  | "warning";

export type TableMenuItem = {
  title: string;
  icon: JSX.Element;
  color: IconButtonColor;
  onClick?: () => void;
  disabled?: boolean;
};
