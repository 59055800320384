import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  useTheme,
  Zoom
} from "@mui/material";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { CustomSelect } from "components/common/CustomSelect";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { PieChartMetric } from "components/common/PieChartMetric";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as backupsActions from "modules/backups/actions";
import * as pollingActions from "modules/polling/actions";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { generateSearchString } from "utils/generateSearchString";
import { getSelectOption } from "utils/getSelectOption";
import { appConfig } from "../../appConfig";
import {
  BACKUPS_STATUSES_FILTER_OPTIONS,
  DEFAULT_REGION,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { TABS } from "./types";
import { BACKUPS_STATUSES, TableBackupsSnapshot } from "modules/backups/types";
import {
  areCachedBackupsSnapshotsLoadingSelector,
  backupsSnapshotsCacheDateSelector,
  cachedBackupsSnapshotsOverviewSelector,
  cachedBackupsSnapshotsSelector,
  isBAckupsSnapshotsCacheUpdatingSelector,
  tableCachedBackupsSnapshotsSelector
} from "modules/backups/selectors";
import { cancelBackupsTokens } from "modules/backups/sagas";

const POLL_ID_PREFIX = "Backups";

const POLL_IDS = {
  backups: "Backups_Overview"
};

const title = "Backups";

const breadcrumbs: Breadcrumb[] = [{ text: "Backups", url: ROUTES.BACKUPS }];

const tableColumns: TableColumn<TableBackupsSnapshot>[] = [
  { key: "id", label: "ID" },
  // { key: "name", label: "Name" },
  { key: "snapshot_type", label: "Type" },
  { key: "status", label: "Status" },
  { key: "size", label: "Size", sortingType: TABLE_SORTING_TYPES.SIZE },
  { key: "time_taken", label: "Time taken" },
  { key: "progress_percent", label: "Progress" },
  { key: "workload_id", label: "Workload" },
  { key: "project_name", label: "Project" },
  { key: "organization_id", label: "OrgID" },
  { key: "region", label: "Region" },
  { key: "email", label: "Email" },
  {
    key: "created_at",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

export const Backups: FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useNavigate();
  const adminRole = useSelector(adminRoleSelector);

  const cachedBackups = useSelector(cachedBackupsSnapshotsSelector);
  const cachedBackupsTable = useSelector(tableCachedBackupsSnapshotsSelector);
  const areCachedBackupsLoading = useSelector(
    areCachedBackupsSnapshotsLoadingSelector
  );

  const cachedBackupsOverview = useSelector(
    cachedBackupsSnapshotsOverviewSelector
  );

  const backupsSnapshotsCacheDate = useSelector(
    backupsSnapshotsCacheDateSelector
  );
  const prevBackupsSnapshotsCacheDate = usePrevious(backupsSnapshotsCacheDate);

  const [isRefreshButtonDisabled, setIsRefreshButtonDisabled] = useState(false);

  const isBackupsSnapshotsCacheUpdating = useSelector(
    isBAckupsSnapshotsCacheUpdatingSelector
  );
  const prevIsBackupsSnapshotsCacheUpdating = usePrevious(
    isBackupsSnapshotsCacheUpdating
  );

  const queryParams = new URLSearchParams(location.search);

  const tableRegionsOptions = (
    appConfig.availableRegions
      ? [
          DEFAULT_REGION,
          ...appConfig.availableRegions.map((region) => ({
            name: region,
            id: region
          }))
        ]
      : []
  ).map((region) => getSelectOption(region, "name", "id"));

  const showOnlyRegionParam = queryParams.get("regions");
  const [showOnlyRegion, setShowOnlyRegion] = useState<string>(
    showOnlyRegionParam || tableRegionsOptions[0].value
  );
  const handleChangeRegionFilter = useCallback((value: string) => {
    setShowOnlyRegion(value);
    // setPage(0);
  }, []);

  const showOnlyStatusParam = queryParams.get("status");
  const [showOnlyStatus, setShowOnlyStatus] = useState<string>(
    showOnlyStatusParam?.toUpperCase() ||
      BACKUPS_STATUSES_FILTER_OPTIONS[0].value
  );
  const handleChangeStatusFilter = useCallback((value: string) => {
    setShowOnlyStatus(value);
    // setPage(0);
  }, []);

  const prevShowOnlyRegion = usePrevious(showOnlyRegion);
  const prevShowOnlyStatus = usePrevious(showOnlyStatus);

  const TAB_TITLES: { [key in TABS]: { title: string; disabled: boolean } } =
    useMemo(
      () => ({
        [TABS.OVERVIEW]: {
          title: "Overview",
          disabled: false
        },
        [TABS.ALL]: {
          title: "All Backups",
          disabled: false
        }
      }),
      []
    );

  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.OVERVIEW) as TABS
  );
  const previousActiveTabIndex = usePrevious(activeTabIndex);

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const handleConfirmUpdateCacheData = useCallback(() => {
    dispatch(backupsActions.updateCacheBackupsSnapshots.started());
    setIsRefreshButtonDisabled(true);
  }, [dispatch]);

  useMount(() => {
    dispatch(
      backupsActions.getAllCachedBackupsSnapshots.started({
        regions: "all",
        status: "ALL"
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(backupsActions.clear());
  });

  useEffect(() => {
    if (backupsSnapshotsCacheDate !== prevBackupsSnapshotsCacheDate) {
      setIsRefreshButtonDisabled(false);
    }
  }, [backupsSnapshotsCacheDate, prevBackupsSnapshotsCacheDate]);

  useEffect(() => {
    if (previousActiveTabIndex !== activeTabIndex) {
      setShowOnlyRegion(tableRegionsOptions[0].value);
      setShowOnlyStatus(BACKUPS_STATUSES_FILTER_OPTIONS[0].value);

      // Cancel the previous requests due to new parameters
      cancelBackupsTokens["listAllCachedBackupsSnapshots"]?.cancel(
        "Cancel the previous request"
      );
      cancelBackupsTokens["listAllCachedBackupsSnapshots"] = null;

      // stop polling
      Object.values(POLL_IDS).forEach((id) => {
        dispatch(
          pollingActions.stopPolling({
            id: `${POLL_ID_PREFIX}/${id}`
          })
        );
      });

      // clear state - to add loaders if change some of the options
      dispatch(backupsActions.clearCachedBackupsSnapshots());

      switch (activeTabIndex) {
        case TABS.OVERVIEW:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.OVERVIEW].title, // tab: "all",
              regions: "all",
              status: "ALL"
            })
          });
          dispatch(
            backupsActions.getAllCachedBackupsSnapshots.started({
              regions: "all",
              status: "ALL"
            })
          );

          break;
        case TABS.ALL:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.ALL].title, // tab: "all",
              regions: String(showOnlyRegion),
              status: String(showOnlyStatus)
            })
          });
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.backups}`,
              action: backupsActions.getAllCachedBackupsSnapshots.started({
                regions: showOnlyRegion,
                status: showOnlyStatus
              })
            })
          );

          break;
      }
    }
  }, [
    previousActiveTabIndex,
    activeTabIndex,
    tableRegionsOptions,
    history,
    TAB_TITLES,
    showOnlyRegion,
    showOnlyStatus,
    prevShowOnlyRegion,
    prevShowOnlyStatus,
    dispatch
  ]);

  useEffect(() => {
    if (
      (prevShowOnlyRegion !== undefined &&
        prevShowOnlyRegion !== showOnlyRegion) ||
      (prevShowOnlyStatus !== undefined &&
        prevShowOnlyStatus !== showOnlyStatus)
    ) {
      switch (activeTabIndex) {
        case TABS.ALL:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.ALL].title, // tab: "all",
              regions: String(showOnlyRegion),
              status: String(showOnlyStatus)
            })
          });
          // Cancel the previous requests due to new parameters
          cancelBackupsTokens["listAllCachedBackupsSnapshots"]?.cancel(
            "Cancel the previous request"
          );
          cancelBackupsTokens["listAllCachedBackupsSnapshots"] = null;

          // stop polling
          dispatch(
            pollingActions.stopPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.backups}`
            })
          );

          // clear state - to add loaders if change some of the options
          dispatch(backupsActions.clearCachedBackupsSnapshots());

          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.backups}`,
              action: backupsActions.getAllCachedBackupsSnapshots.started({
                regions: showOnlyRegion,
                status: showOnlyStatus
              })
            })
          );

          break;
      }
    }
  }, [
    previousActiveTabIndex,
    activeTabIndex,
    tableRegionsOptions,
    history,
    TAB_TITLES,
    showOnlyRegion,
    showOnlyStatus,
    prevShowOnlyRegion,
    prevShowOnlyStatus,
    dispatch
  ]);

  const generateCachedTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.USER, {
        userId: cachedBackupsTable?.find((vm) => vm.id == id)?.user_id
      }),
    [cachedBackupsTable]
  );

  const statusColors = {
    [BACKUPS_STATUSES.AVAILABLE]: theme.palette.secondary.light, //"#07C5C2",
    [BACKUPS_STATUSES.EXECUTING]: "#07C5C2", // theme.palette.success.light,
    [BACKUPS_STATUSES.CANCELLED]: theme.palette.secondary.dark, //"#A04874", // theme.palette.warning.light,
    [BACKUPS_STATUSES.ERROR]: "#662d49" //theme.palette.secondary.dark //"#C2185B"
  };

  const typesColors = {
    ["unsecured"]: theme.palette.secondary.dark,
    ["protected"]: theme.palette.secondary.light
  };

  const renderSimpleMetric = (title, value, unit, helperText = "") => {
    return (
      // <s.MetricWrapper>
      <s.SimpleMetricsContainer>
        <Tooltip
          title={helperText}
          arrow
          placement={"top"}
          TransitionComponent={Zoom}
        >
          <s.SimpleMetricItem2>
            <s.SimpleMetricTitle>{title}</s.SimpleMetricTitle>
            <s.SimpleMetricValue>
              {value} {unit}
            </s.SimpleMetricValue>
          </s.SimpleMetricItem2>
        </Tooltip>
      </s.SimpleMetricsContainer>
      // </s.MetricWrapper>
    );
  };

  const renderMetric = (
    title: string,
    statusData: { [key: string]: number | undefined },
    total: number,
    unit: string = "",
    simpleMetrics: {
      label: string;
      value: number;
      unit: string;
      width?: string;
    }[],
    width?: number,
    showTotalString?: boolean,
    showUnitInLegend?: boolean,
    sortDescending?: boolean,
    // maxItemsInLegend?: number,
    colorSource?: "status" | "",
    lowerCaseLegend?: boolean
    // showPopoverOnOtherClick?: boolean
  ) => {
    const colorPalette = colorSource === "status" ? statusColors : typesColors;

    const dataChart = Object.entries(statusData)
      .filter(([, count]) => count !== undefined)
      .map(([status, count]) => ({
        label: lowerCaseLegend ? status.toLowerCase() : status,
        value: count as number,
        color: colorPalette[status as keyof typeof colorPalette]
      }));

    return (
      <PieChartMetric
        title={title}
        dataChart={dataChart}
        total={total}
        unit={unit}
        simpleMetrics={simpleMetrics}
        width={width}
        showTotalString={showTotalString}
        showUnitInLegend={showUnitInLegend}
        sortDescending={sortDescending}
        // maxItemsInLegend={maxItemsInLegend}
        // showPopoverOnOtherClick={showPopoverOnOtherClick}
      />
    );
  };

  const tabContent = [
    <>
      <s.MetricsContainer variant={"outlined"}>
        {!cachedBackupsOverview ? (
          <Loader text={"Loading data..."} />
        ) : (
          <>
            {cachedBackupsOverview.map((data, index) => (
              <div key={index}>
                <s.TitleRegion component="h4">{data.region}</s.TitleRegion>
                <s.MetricRowContainer key={index}>
                  <s.MetricContainer>
                    {renderMetric(
                      "Statuses",
                      data.statistics_by_statuses || {},
                      data.total_snapshots_count,
                      "backups",
                      [],
                      370, // width
                      true, // showTotalString
                      false, // showUnitInLegend
                      false, // sortDescending
                      // 7, // maxItemsInLegend
                      "status", // colorPalette source
                      true //lowerCaseLegend
                      // true //showPopoverOnOtherClick
                    )}
                  </s.MetricContainer>
                  <s.MetricContainer>
                    {renderSimpleMetric(
                      "Total Backups",
                      data.total_snapshots_count,
                      ""
                    )}
                    {renderSimpleMetric(
                      "Total Backups Size",
                      data.total_snapshots_size,
                      ""
                    )}
                    {renderSimpleMetric(
                      "Protected VMs",
                      data.protected_servers_count,
                      ""
                    )}
                  </s.MetricContainer>

                  <s.MetricContainer>
                    {renderMetric(
                      "Coverage",
                      {
                        protected: data.protected_servers_count || 0,
                        unsecured:
                          data.total_servers_count -
                            data.protected_servers_count || 0
                      },
                      data.total_servers_count,
                      "VMs",
                      [],
                      355, // width
                      true, // showTotalString
                      true, // showUnitInLegend
                      false, // sortDescending
                      // 7, // maxItemsInLegend
                      "", // colorPalette source
                      false //lowerCaseLegend
                      // true //showPopoverOnOtherClick
                    )}
                  </s.MetricContainer>
                </s.MetricRowContainer>
              </div>
            ))}
          </>
        )}
      </s.MetricsContainer>
      {/* <s.MetricsContainer variant={"outlined"}>
        {!cachedBackupsOverview ? (
          <Loader text={"Loading data..."} />
        ) : (
          <>
            <s.MetricRowContainer>
              {cachedBackupsOverview.map((data, index) => (
                <s.MetricContainer key={index}>
                  {renderMetric(
                    data.region.toUpperCase(),
                    data.statistics_by_statuses || {},
                    data.total_snapshots_count,
                    "backups",
                    [
                      {
                        label: "Total backups",
                        value: data.total_snapshots_count,
                        unit: ""
                      },
                      {
                        label: "Protected VMs",
                        value: data.protected_servers_count,
                        unit: ""
                      }
                      // {
                      //   label: "Full backups",
                      //   value: data.total_full_snapshots_count,
                      //   unit: ""
                      // },
                      // {
                      //   label: "Incremental backups",
                      //   value: data.total_incremental_snapshots_count,
                      //   unit: ""
                      // }
                    ],
                    // 355, // width
                    false, // showTotalString
                    false, // showUnitInLegend
                    true, // sortDescending
                    // 7, // maxItemsInLegend
                    "status", // colorPalette source
                    false //lowerCaseLegend
                    // true //showPopoverOnOtherClick
                  )}
                </s.MetricContainer>
              ))}
            </s.MetricRowContainer>
          </>
        )}
      </s.MetricsContainer> */}
    </>,
    <>
      <Table<TableBackupsSnapshot>
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={cachedBackupsTable || []}
        columns={tableColumns}
        isLoading={!cachedBackupsTable}
        itemLink={{
          column: "email",
          getURL: generateCachedTableItemURL
        }}
        toolbarItems={
          <>
            <Box style={{ display: "flex", flex: 1 }}>
              <CustomSelect
                label={"Region"}
                options={tableRegionsOptions}
                defaultValue={tableRegionsOptions[0].value}
                value={showOnlyRegion}
                onChange={handleChangeRegionFilter}
                margin={"0 15px 0 0"}
              />
              <CustomSelect
                label={"Status"}
                options={BACKUPS_STATUSES_FILTER_OPTIONS}
                defaultValue={BACKUPS_STATUSES_FILTER_OPTIONS[0].value}
                value={showOnlyStatus}
                onChange={handleChangeStatusFilter}
                margin={"0 25px 0 0"}
              />
            </Box>
            <s.CashContainer2>
              <s.ActionsContainer>
                {backupsSnapshotsCacheDate && (
                  <Tooltip title={"Report updated at:"} placement={"top"} arrow>
                    <span>
                      <s.Description>
                        Report date: {backupsSnapshotsCacheDate}
                      </s.Description>
                    </span>
                  </Tooltip>
                )}

                <Tooltip title={"Refresh report"} placement={"top"} arrow>
                  <span>
                    <IconButton
                      onClick={handleConfirmUpdateCacheData}
                      color={"inherit"}
                      disabled={isRefreshButtonDisabled || !cachedBackupsTable}
                    >
                      <s.RotatingRefreshIcon
                        $isSpinning={isRefreshButtonDisabled}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </s.ActionsContainer>
            </s.CashContainer2>
          </>
        }
      />
    </>,
    <></>
  ];

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.SummaryContainer>
        <s.Title variant={"h4"} component={"h2"}>
          {title}
        </s.Title>
      </s.SummaryContainer>
      <s.TabsContainer>
        <Tabs value={activeTabIndex} onChange={handleChangeTab}>
          {Object.values(TAB_TITLES).map((tab) => (
            <Tab key={tab.title} label={tab.title} disabled={tab.disabled} />
          ))}
        </Tabs>
      </s.TabsContainer>
      {tabContent[activeTabIndex]}
    </>
  );
};
