export const formatSize = (bytes: number): string => {
  if (bytes === 0) {
    return "0 B";
  }

  const k = 1024;
  const sizes: string[] = ["B", "KB", "MB", "GB", "TB"];

  const i: number = Math.floor(Math.log(bytes) / Math.log(k));
  const fileSize: number = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

  return `${fileSize} ${sizes[i]}`;
};

export const parseSizeToBytes = (size: string): number => {
  if (typeof size !== "string" || size.trim() === "") return 0;

  const sizes = ["B", "KB", "MB", "GB", "TB"];
  const regex = /^([\d.]+)\s?(B|KB|MB|GB|TB)$/i;
  const match = size.match(regex);

  if (!match) return 0;

  const value = parseFloat(match[1]);
  const unit = match[2].toUpperCase();
  const exponent = sizes.indexOf(unit);

  if (exponent === -1) return 0;

  return Math.round(value * Math.pow(1024, exponent));
};

export interface MemorySize {
  value: number;
  unit: string;
}

export const formatMemorySize = (memoryInMB: number): MemorySize => {
  if (memoryInMB === 0) {
    return { value: 0, unit: "MB" };
  }

  const k = 1024;
  const sizes: string[] = ["MB", "GB", "TB", "PB"];

  const i: number = Math.min(
    Math.floor(Math.log(memoryInMB) / Math.log(k)),
    sizes.length - 1
  );
  const formattedSize: number = parseFloat(
    (memoryInMB / Math.pow(k, i)).toFixed(2)
  );

  return { value: formattedSize, unit: sizes[i] };
};

export const formatMemorySizeBytes = (
  bytes: number
): { value: string; unit: string } => {
  if (bytes === 0) {
    return { value: "0", unit: "B" };
  }

  const k = 1024;
  const sizes: string[] = ["B", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const fileSize = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

  return { value: fileSize.toString(), unit: sizes[i] };
};
