import actionCreatorFactory from "typescript-fsa";
import {
  GetS3StorageMetricsReportParams,
  GetS3StorageMetricsReportResponse,
  GetS3StorageTopUsersReportParams,
  GetS3StorageTopUsersReportResponse,
  UpdateCacheS3StorageReportResponse
} from "./types";

const actionCreator = actionCreatorFactory("S3_STORAGE");

export const getS3StorageTopUsersReport = actionCreator.async<
  GetS3StorageTopUsersReportParams,
  GetS3StorageTopUsersReportResponse,
  unknown
>("GET_S3_STORAGE_TOP_USERS_REPORT");

export const getS3StorageMetricsReport = actionCreator.async<
  GetS3StorageMetricsReportParams,
  GetS3StorageMetricsReportResponse,
  unknown
>("GET_S3_STORAGE_METRICS_REPORT");

export const updateCacheS3StorageReport = actionCreator.async<
  void,
  UpdateCacheS3StorageReportResponse,
  unknown
>("UPDATE_CACHE_S3_STORAGE_REPORT");

export const clearS3StorageTopUsersList = actionCreator<void>(
  "CLEAR_TOP_USERS_LIST"
);

export const clear = actionCreator<void>("CLEAR");
