import { PROJECT_STATUSES } from "components/BillingOrganization/types";
import { ORG_STATUSES } from "components/User/types";
import { ROLES } from "modules/enterprises/types";
import { appConfig } from "./appConfig";

export const ELEMENT_IDS = {
  REACT_ROOT: "root",
  CONTENT_CONTAINER: "content-container",
  BREADCRUMBS_CONTAINER: "breadcrumbs-container"
};

export const ROUTES = {
  ROOT: "/",
  USERS: "/users",
  USER: "/users/:userId",
  BILLING_PROJECT:
    "/users/:userId/organizationsBilling/:organizationId/regions/:regionId/projectsBilling/:projectId",
  ORGANIZATIONS: "/organizations",
  ORGANIZATION: "/organizations/:organizationId",
  BILLING_ORGANIZATION: "/users/:userId/organizationsBilling/:organizationId",
  GROUP: "/group",
  PROJECTS: "/projects",
  PROJECT: "/projects/:projectId/regions/:region/",
  VIRTUAL_MACHINES: "/virtual-machines",
  BACKUPS: "/backups",
  CLUSTERS: "/clusters",
  FAILED_RESOURCES: "/failed-resources",
  HYPERVISORS_OVERVIEW: "/monitoring/hypervisors-overview",
  UTILIZATION: "/utilization",
  RAM: "/monitoring/ram",
  CPU: "/monitoring/cpu",
  DISK_SPACE: "/monitoring/disk_space",
  NETWORK_USAGE: "/monitoring/network-usage",
  S3_STORAGE_USAGE: "/monitoring/s3-storage-usage",
  // PUBLIC_IPS: "/public-ips",
  BILLING_CHARTS: "/billing-charts"
};

export const REGEX = {
  EMAIL_ADDRESS:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // source: https://emailregex.com
  PASSWORD:
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/,
  BILLING_RECORD_COST_KEY: /^cost_[a-z]{3}$/,
  BILLING_RECORD_TOTAL_COST_KEY: /^total-cost-[a-z]{3}$/,
  NAME_DOES_NOT_START_WITH_WHITESPACE: /^[^ ].*$/,
  NAME_DOES_NOT_END_WITH_WHITESPACE: /^.*[^ ]$/,
  NAME_DOES_NOT_CONTAIN_SEQUENTIAL_WHITESPACES: /^(?!.* {2}).*$/,
  NAME_STARTS_WITH_LETTER: /^[A-Za-z].*$/,
  ORGANIZATION_NAME: /^[^#]+$/,
  PROJECT_NAME: /^[^#:]+$/,
  GROUP_NAME: /^[^#]+$/
};

export enum DATE_FORMATS {
  ISO_DATE = "yyyy-MM-dd",
  DATE = "d MMM yyyy",
  DATETIME = "d MMM yyyy HH:mm:ss",
  TIME = "HH:mm:ss"
}

export const DEFAULT_CURRENCY = "eur";
export const DEFAULT_REGION = { name: "All regions", id: "all" };
export const DEFAULT_USERS_TO_SHOW = "users";

export const DEFAULT_PAGINATION_LIMIT = 10;
export const MAX_PAGINATION_LIMIT = 100;
export const DEFAULT_DEBOUNCE_DELAY = 1000; // in milliseconds
export const DEFAULT_TRIAL_DAYS = 60;

export const ORG_STATUSES_LABELS = {
  [ORG_STATUSES.ACTIVE]: "Active",
  [ORG_STATUSES.DELETED]: "Deleted"
};

export const PROJECT_STATUSES_LABELS = {
  [PROJECT_STATUSES.ACTIVE]: "Active",
  [PROJECT_STATUSES.DELETED]: "Deleted"
};

export const ERROR_MESSAGES = {
  REQUIRED: "Field is required.",
  EMAIL_ADDRESS: "E-mail address has not valid format.",
  PASSWORD:
    "Password must contain at least one Latin letter in upper case (A-Z), one Latin letter in lower case (a-z), digit (0-9) and special character ( !\"#$%&'()*+,-./:;<=>?@[]^_`{|}~) and must be at least 8 characters long.",
  PASSWORDS_MUST_MATCH: "Passwords must match.",
  NAME_DOES_NOT_START_WITH_WHITESPACE: "Name must not start with whitespace.",
  NAME_DOES_NOT_END_WITH_WHITESPACE: "Name must not end with whitespace.",
  NAME_DOES_NOT_CONTAIN_SEQUENTIAL_WHITESPACES:
    "Name must not contain sequential whitespaces.",
  NAME_DOES_NOT_CONTAIN_COLON: "Name must not contain colon.",
  NAME_DOES_NOT_CONTAIN_NUMBER_SIGN: "Name must not contain number sign.",
  NAME_STARTS_WITH_LETTER: "Name must start with Latin letter (a-z, A-Z).",
  ORGANIZATION_NAME: "Name must not contain number sign (#).",
  // ORGANIZATIONS_NUMBER: `The allowed number of organizations cannot be less than ${appConfig.defaultAllowedOrgNumber}.`,
  // PROJECTS_NUMBER: `The allowed number of projects cannot be less than ${appConfig.defaultAllowedProjectNumber}.`,
  ORGANIZATIONS_NUMBER: `The allowed number of organizations cannot be less than 0.`,
  PROJECTS_NUMBER: `The allowed number of projects cannot be less than 0.`,
  PROJECT_NAME: "Name must not contain number sign (#) or colon (:).",
  GROUP_NAME: "Name must not contain number sign (#).",
  TRIAL_DAYS: `The allowed number of trial days cannot be less than 0 and more than 365 days.`
};

export const ADMIN_ROLES = {
  USER_MANAGEMENT: "user_management", // organizations, projects within org, users
  OPERATOR: "operator", // tab projects all actions, tab users only see info
  OBSERVE: "observe",
  BILLING: "billing", // billing tab in users, generate billing report
  ADMIN_ROLES_MANAGEMENT: "admin_roles_management" // set admins (but only with user_management)
};

export const ENTITY_NAME_LENGTH = 255;
export const PROJECT_NAME_LENGTH = 100;

export const ROLES_IN_ORG = {
  [ROLES.ADMIN]: "Administrator",
  [ROLES.MEMBER]: "Member",
  [ROLES.OWNER]: "Owner"
};

export const USERS_FILTER_OPTIONS = [
  { value: "users", label: "All Users" },
  { value: "trial", label: "Trial Users" },
  { value: "internal", label: "Internal Users" },
  { value: "admins", label: "Cloud Admins" },
  { value: "owners", label: "Organization Owners" }
];

export const VMS_STATUSES_FILTER_OPTIONS = [
  { value: "ALL", label: "All Statuses" },
  { value: "ACTIVE", label: "Active" },
  { value: "ERROR", label: "Error" },
  { value: "BUILD", label: "Build" },
  { value: "REBUILD", label: "Rebuild" },
  { value: "REBOOT", label: "Reboot" },
  { value: "HARD_REBOOT", label: "Hard Reboot" },
  { value: "PAUSED", label: "Paused" },
  { value: "SHELVED", label: "Shelved" },
  { value: "SHELVED_OFFLOADED", label: "Shelved Offloaded" },
  { value: "SHUTOFF", label: "Shutoff" },
  { value: "RESIZE", label: "Resize" },
  { value: "VERIFY_RESIZE", label: "Verify Resize" },
  { value: "REVERT_RESIZE", label: "Revert Resize" },
  { value: "MIGRATING", label: "Migrating" },
  { value: "RESCUE", label: "Rescue" },
  { value: "SUSPENDED", label: "Suspend" }
];

export const VMS_STATUSES_DESCRIPTION = [
  { value: "ACTIVE", label: "The server is active." },
  { value: "ERROR", label: "The server is in error." },
  {
    value: "BUILD",
    label: "The server has not yet finished the original build process."
  },
  {
    value: "REBUILD",
    label: "The server is currently being rebuilt from an image."
  },
  {
    value: "REBOOT",
    label:
      "The server is in a soft reboot state. A reboot command was passed to the operating system."
  },
  {
    value: "HARD_REBOOT",
    label:
      "The server is hard rebooting. This is equivalent to pulling the power plug on a physical server, plugging it back in, and rebooting it."
  },
  { value: "PAUSED", label: "The server is paused." },
  {
    value: "SHELVED",
    label:
      "The server is in shelved state. Depends on the shelve offload time, the server will be automatically shelved off loaded."
  },
  {
    value: "SHELVED_OFFLOADED",
    label:
      "SThe shelved server is offloaded (removed from the compute host) and it needs unshelved action to be used again."
  },
  {
    value: "SHUTOFF",
    label:
      "The server was powered down by the user, either through the OpenStack Compute API or from within the server."
  },
  {
    value: "RESIZE",
    label:
      "Server is performing the differential copy of data that changed during its initial copy. Server is down for this stage."
  },
  {
    value: "VERIFY_RESIZE",
    label:
      "System is awaiting confirmation that the server is operational after a move or resize."
  },
  {
    value: "REVERT_RESIZE",
    label:
      "The resize or migration of a server failed for some reason. The destination server is being cleaned up and the original source server is restarting."
  },
  {
    value: "MIGRATING",
    label:
      "The server is migrating. This is caused by a live migration (moving a server that is active) action."
  },
  { value: "PASSWORD", label: "The password is being reset on the server." },
  { value: "RESCUE", label: "The server is in rescue mode." },
  {
    value: "SUSPENDED",
    label:
      "The server is suspended, either by request or necessity. When you suspend a server, its state is stored on disk, all memory is written to disk, and the server is stopped."
  },
  { value: "DELETED", label: "The server is deleted." },
  {
    value: "SOFT_DELETED",
    label:
      "The server is marked as deleted but will remain in the cloud for some configurable amount of time. While soft-deleted, an authorized user can restore the server back to normal state. When the time expires, the server will be deleted permanently."
  },
  {
    value: "UNKNOWN",
    label:
      "The state of the server is unknown. It could be because a part of the infrastructure is temporarily down."
  }
];

export const BACKUPS_STATUSES_FILTER_OPTIONS = [
  { value: "ALL", label: "All Statuses" },
  { value: "AVAILABLE", label: "Available" },
  { value: "EXECUTING", label: "Executing" },
  { value: "CANCELLED", label: "Cancelled" },
  { value: "ERROR", label: "Error" }
];

export enum BACKUPS_STATUSES {
  AVAILABLE = "available",
  CANCELLED = "cancelled",
  ERROR = "error",
  EXECUTING = "executing"
}

export const ADMIN_REMINDER_INTERVAL_IN_MIN = 60;
