import axios, { AxiosResponse, CancelTokenSource } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  GetS3StorageMetricsReportParams,
  GetS3StorageMetricsReportResponse,
  GetS3StorageTopUsersReportParams,
  GetS3StorageTopUsersReportResponse,
  UpdateCacheS3StorageReportResponse
} from "./types";

export const cancelS3StorageMonitoringTokens: {
  [key: string]: CancelTokenSource | undefined | null;
} = {};

export function* getS3StorageTopUsersReportSaga(
  action: Action<GetS3StorageTopUsersReportParams>
): SagaIterator<void> {
  try {
    // Create a new token if it doesn't exist
    if (!cancelS3StorageMonitoringTokens["s3StorageTopUsersReport"]) {
      cancelS3StorageMonitoringTokens["s3StorageTopUsersReport"] =
        axios.CancelToken.source();
    }

    const { regions, top, attribute } = action.payload;
    const response: AxiosResponse<GetS3StorageTopUsersReportResponse> =
      yield call(
        axiosInstance.get,
        `gotham-wayne-tower/method/admin/storage/top-usage`,
        {
          params: {
            regions,
            top,
            attribute
          },
          cancelToken:
            cancelS3StorageMonitoringTokens["s3StorageTopUsersReport"]?.token
        }
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getS3StorageTopUsersReport.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getS3StorageTopUsersReport.failed({
          params: action.payload,
          error: e
        })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get s3 storage top users data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* getS3StorageMetricsReportSaga(
  action: Action<GetS3StorageMetricsReportParams>
): SagaIterator<void> {
  try {
    // Create a new token if it doesn't exist
    if (!cancelS3StorageMonitoringTokens["s3StorageMetricsReport"]) {
      cancelS3StorageMonitoringTokens["s3StorageMetricsReport"] =
        axios.CancelToken.source();
    }

    // const { regions, top, attribute } = action.payload;
    const response: AxiosResponse<GetS3StorageMetricsReportResponse> =
      yield call(
        axiosInstance.get,
        `gotham-wayne-tower/method/admin/storage/overview`,
        {
          params: {},
          cancelToken:
            cancelS3StorageMonitoringTokens["s3StorageMetricsReport"]?.token
        }
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getS3StorageMetricsReport.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getS3StorageMetricsReport.failed({
          params: action.payload,
          error: e
        })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get s3 storage metrics data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* updateCacheS3StorageReportSaga(
  action: Action<void>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<UpdateCacheS3StorageReportResponse> =
      yield call(
        axiosInstance.get,
        `gotham-wayne-tower/method/admin/storage/usage/force-cache-update`
      );

    yield put(
      actions.updateCacheS3StorageReport.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title:
          "S3 Storage report for all regions requested. Expect it within a few minutes.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateCacheS3StorageReport.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update cached s3 storage data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeEvery(
      actions.getS3StorageTopUsersReport.started,
      getS3StorageTopUsersReportSaga
    ),
    takeEvery(
      actions.getS3StorageMetricsReport.started,
      getS3StorageMetricsReportSaga
    ),
    takeEvery(
      actions.updateCacheS3StorageReport.started,
      updateCacheS3StorageReportSaga
    )
  ]);
}
