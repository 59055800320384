import { Box, IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { CustomSelect } from "components/common/CustomSelect";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as s3StorageActions from "modules/s3_storage/actions";
import * as pollingActions from "modules/polling/actions";
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { generateSearchString } from "utils/generateSearchString";
import { getSelectOption } from "utils/getSelectOption";
import { appConfig } from "../../appConfig";
import { DEFAULT_REGION, ROUTES } from "../../constants";
import * as s from "./styles";
import { DEFAULT_TOP_USERS_NUMBER, TABS } from "./types";
import { Loader } from "components/common/Loader";
import React from "react";
import {
  S3_GET_REPORT_ATTR_OPTIONS,
  S3_GET_REPORT_TOP_OPTIONS,
  S3StorageReportAttribute,
  TableS3StorageTopUsersReport
} from "modules/s3_storage/types";
import { cancelS3StorageMonitoringTokens } from "modules/s3_storage/sagas";
import {
  tableS3StorageTopUsersReportSelector,
  s3StorageMetricsReportSelector,
  isS3StorageMetricsReportLoadingSelector,
  isS3StorageTopUsersReportLoadingSelector,
  regionS3StorageMetricsReportSelector,
  s3StorageMetricsReportFormattedSelector,
  s3StorageCacheDateSelector,
  isS3StorageReportCacheUpdatingSelector,
  tableTotalS3StorageTopUsersReportSelector
} from "modules/s3_storage/selectors";
import { PieChartMetric } from "components/common/PieChartMetric";
import { formatMemorySizeBytes } from "utils/formatSize";

const POLL_ID_PREFIX = "S3_STORAGE_MONITORING";

const POLL_IDS = {
  top_region_users: "TOP_REGION_USERS",
  metrics: "S3_USAGE_METRICS"
};

const title = "S3 Storage Usage";

const breadcrumbs: Breadcrumb[] = [
  { text: "S3 Storage Monitoring", url: ROUTES.S3_STORAGE_USAGE }
];

const tableColumns: TableColumn<TableS3StorageTopUsersReport>[] = [
  { key: "id", label: "Project ID" },
  { key: "project_name", label: "Project Name" },
  { key: "organization_id", label: "Org ID" },
  // { key: "user_name", label: "Owner" },
  { key: "email", label: "Email" },
  { key: "region", label: "Region" },
  {
    key: "storage_buckets",
    label: "Buckets",
    sortingType: TABLE_SORTING_TYPES.NUMBER
  },
  {
    key: "storage_objects",
    label: "Objects",
    sortingType: TABLE_SORTING_TYPES.NUMBER
  },
  { key: "storage_size", label: "Size", sortingType: TABLE_SORTING_TYPES.SIZE }
];

export const S3StorageUsage: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const s3StorageMetricsReport = useSelector(s3StorageMetricsReportSelector);
  const tableS3StorageTopUsersReport = useSelector(
    tableS3StorageTopUsersReportSelector
  );
  const tableS3StorageTopUsersReportTotal = useSelector(
    tableTotalS3StorageTopUsersReportSelector
  );
  const regionS3StorageMetricsReport = useSelector(
    regionS3StorageMetricsReportSelector
  );
  const s3StorageMetricsReportFormatted = useSelector(
    s3StorageMetricsReportFormattedSelector
  );
  const isS3StorageMetricsReportLoading = useSelector(
    isS3StorageMetricsReportLoadingSelector
  );
  const isS3StorageTopUsersReportLoading = useSelector(
    isS3StorageTopUsersReportLoadingSelector
  );

  // const publicIPsTotal = useSelector(publicIPsTotalSelector);

  const s3StorageCacheDate = useSelector(s3StorageCacheDateSelector);
  const prevS3StorageCacheDate = usePrevious(s3StorageCacheDate);
  const [isRefreshButtonDisabled, setIsRefreshButtonDisabled] = useState(false);

  const isS3StorageReportCacheUpdating = useSelector(
    isS3StorageReportCacheUpdatingSelector
  );
  const prevIsS3StorageReportCacheUpdating = usePrevious(
    isS3StorageReportCacheUpdating
  );

  const queryParams = new URLSearchParams(location.search);

  const searchParams = queryParams.get("search");
  const [search, setSearch] = useState<string>(
    searchParams ? String(searchParams) : ""
  );
  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const tableRegionsOptions = (
    appConfig.availableRegions
      ? [
          DEFAULT_REGION,
          ...appConfig.availableRegions.map((region) => ({
            name: region,
            id: region
          }))
        ]
      : []
  ).map((region) => getSelectOption(region, "name", "id"));

  const showOnlyRegionParam = queryParams.get("regions");
  const [showOnlyRegion, setShowOnlyRegion] = useState<string>(
    showOnlyRegionParam || tableRegionsOptions[0].value
  );
  const handleChangeRegionFilter = useCallback((value: string) => {
    setShowOnlyRegion(value);
  }, []);

  const showOnlyTopParam = queryParams.get("top");
  const [showOnlyTop, setShowOnlyTop] = useState<string>(
    showOnlyTopParam || DEFAULT_TOP_USERS_NUMBER
  );
  const handleChangeTopFilter = useCallback((value: string) => {
    setShowOnlyTop(value);
    // setPage(0);
  }, []);

  const showOnlyAttrParam = queryParams.get("attribute");
  const [showOnlyAttr, setShowOnlyAttr] = useState<S3StorageReportAttribute>(
    (showOnlyTopParam as S3StorageReportAttribute) ||
      S3StorageReportAttribute.Size
  );
  const handleChangeAttrFilter = useCallback((value: string) => {
    setShowOnlyAttr(value as S3StorageReportAttribute);
    // setPage(0);
  }, []);

  const prevShowOnlyRegion = usePrevious(showOnlyRegion);
  const prevShowOnlyTop = usePrevious(showOnlyTop);
  const prevShowOnlyAttr = usePrevious(showOnlyAttr);

  const S3_MONITORING_TAB_TITLES: {
    [key in TABS]: { title: string; disabled: boolean };
  } = useMemo(
    () => ({
      [TABS.USAGE]: {
        title: "Usage",
        disabled: false
      },
      [TABS.TOP_S3_USERS]: {
        title: "Top Users",
        disabled: false
      }
    }),
    []
  );

  const activeTabIndexFromParam = Object.keys(S3_MONITORING_TAB_TITLES).find(
    (key) =>
      S3_MONITORING_TAB_TITLES[key] &&
      String(S3_MONITORING_TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.USAGE) as TABS
  );
  const previousActiveTabIndex = usePrevious(activeTabIndex);

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
    // setPage(0);
  }, []);

  const handleConfirmUpdateCacheData = useCallback(() => {
    dispatch(s3StorageActions.updateCacheS3StorageReport.started());
    setIsRefreshButtonDisabled(true);
  }, [dispatch]);

  useMount(() => {
    dispatch(s3StorageActions.getS3StorageMetricsReport.started({}));
    // dispatch(
    //   s3StorageActions.getS3StorageTopUsersReport.started({
    //     regions: showOnlyRegion,
    //     top: Number(showOnlyTop),
    //     attribute: showOnlyAttr
    //   })
    // );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(s3StorageActions.clear());
  });

  useEffect(() => {
    if (s3StorageCacheDate !== prevS3StorageCacheDate) {
      setIsRefreshButtonDisabled(false);
    }
  }, [prevS3StorageCacheDate, s3StorageCacheDate]);

  useEffect(() => {
    if (
      previousActiveTabIndex !== undefined &&
      previousActiveTabIndex !== activeTabIndex
    ) {
      setShowOnlyRegion(tableRegionsOptions[0].value);
      setShowOnlyTop(DEFAULT_TOP_USERS_NUMBER);
      setShowOnlyAttr(S3StorageReportAttribute.Size);

      // Cancel the previous requests due to new parameters
      cancelS3StorageMonitoringTokens["s3StorageTopUsersReport"]?.cancel(
        "Cancel the previous request"
      );
      cancelS3StorageMonitoringTokens["s3StorageTopUsersReport"] = null;

      cancelS3StorageMonitoringTokens["s3StorageMetricsReport"]?.cancel(
        "Cancel the previous request"
      );
      cancelS3StorageMonitoringTokens["s3StorageMetricsReport"] = null;

      // stop polling
      Object.values(POLL_IDS).forEach((id) => {
        dispatch(
          pollingActions.stopPolling({
            id: `${POLL_ID_PREFIX}/${id}`
          })
        );
      });

      // clear state - to add loaders if change some of the options
      dispatch(s3StorageActions.clearS3StorageTopUsersList());
      // dispatch(s3StorageActions.clear());

      switch (activeTabIndex) {
        case TABS.USAGE:
          history({
            search: generateSearchString({
              tab: S3_MONITORING_TAB_TITLES[TABS.USAGE].title,
              regions: "all",
              top: String(DEFAULT_TOP_USERS_NUMBER),
              attribute: S3StorageReportAttribute.Size
            })
          });
          dispatch(s3StorageActions.getS3StorageMetricsReport.started({}));

          break;
        case TABS.TOP_S3_USERS:
          history({
            search: generateSearchString({
              tab: S3_MONITORING_TAB_TITLES[TABS.TOP_S3_USERS].title,
              regions: String(showOnlyRegion),
              top: String(showOnlyTop),
              attribute: String(showOnlyAttr)
            })
          });
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.top_region_users}`,
              action: s3StorageActions.getS3StorageTopUsersReport.started({
                regions: showOnlyRegion,
                top: Number(showOnlyTop),
                attribute: showOnlyAttr
              })
            })
          );
          break;
      }
    }
  }, [
    previousActiveTabIndex,
    activeTabIndex,
    tableRegionsOptions,
    history,
    showOnlyRegion,
    prevShowOnlyRegion,
    dispatch,
    S3_MONITORING_TAB_TITLES,
    showOnlyTop,
    showOnlyAttr
  ]);

  useEffect(() => {
    if (
      (prevShowOnlyRegion !== undefined &&
        prevShowOnlyRegion !== showOnlyRegion) ||
      (prevShowOnlyTop !== undefined && prevShowOnlyTop !== showOnlyTop) ||
      (prevShowOnlyAttr !== undefined && prevShowOnlyAttr !== showOnlyAttr)
    ) {
      switch (activeTabIndex) {
        case TABS.USAGE:
          history({
            search: generateSearchString({
              tab: S3_MONITORING_TAB_TITLES[TABS.USAGE].title,
              regions: "all",
              top: String(DEFAULT_TOP_USERS_NUMBER),
              attribute: S3StorageReportAttribute.Size
            })
          });
          // Cancel the previous requests due to new parameters
          cancelS3StorageMonitoringTokens["s3StorageMetricsReport"]?.cancel(
            "Cancel the previous request"
          );
          cancelS3StorageMonitoringTokens["s3StorageMetricsReport"] = null;

          // stop polling
          // dispatch(
          //   pollingActions.stopPolling({
          //     id: `${POLL_ID_PREFIX}/${POLL_IDS.metrics}`
          //   })
          // );

          // clear state - to add loaders if change some of the options
          // dispatch(s3StorageActions.clear());

          dispatch(s3StorageActions.getS3StorageMetricsReport.started({}));
          break;

        case TABS.TOP_S3_USERS:
          history({
            search: generateSearchString({
              tab: S3_MONITORING_TAB_TITLES[TABS.TOP_S3_USERS].title,
              regions: String(showOnlyRegion),
              top: String(showOnlyTop),
              attribute: String(showOnlyAttr)
            })
          });
          // Cancel the previous requests due to new parameters
          cancelS3StorageMonitoringTokens["s3StorageTopUsersReport"]?.cancel(
            "Cancel the previous request"
          );
          cancelS3StorageMonitoringTokens["s3StorageTopUsersReport"] = null;

          // stop polling
          dispatch(
            pollingActions.stopPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.top_region_users}`
            })
          );

          // clear state - to add loaders if change some of the options
          dispatch(s3StorageActions.clearS3StorageTopUsersList());

          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.top_region_users}`,
              action: s3StorageActions.getS3StorageTopUsersReport.started({
                regions: showOnlyRegion,
                top: Number(showOnlyTop),
                attribute: showOnlyAttr
              })
            })
          );

          break;
      }
    }
  }, [
    previousActiveTabIndex,
    activeTabIndex,
    tableRegionsOptions,
    history,
    showOnlyRegion,
    prevShowOnlyRegion,
    prevShowOnlyAttr,
    dispatch,
    prevShowOnlyTop,
    showOnlyTop,
    showOnlyAttr,
    S3_MONITORING_TAB_TITLES
  ]);

  const generateS3StorageTopUsersTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.USER, {
        userId: tableS3StorageTopUsersReport?.find((vm) => vm.id == id)?.user_id
      }),
    [tableS3StorageTopUsersReport]
  );

  const renderSimpleMetric = (title, value, unit, helperText = "") => {
    return (
      <s.SimpleMetricsContainer>
        <Tooltip title={helperText} arrow placement={"top"}>
          <s.SimpleMetricItem>
            <s.SimpleMetricTitle>{title}</s.SimpleMetricTitle>
            <s.SimpleMetricValue>
              {value} {unit}
            </s.SimpleMetricValue>
          </s.SimpleMetricItem>
        </Tooltip>
      </s.SimpleMetricsContainer>
    );
  };

  // const regionColors = {
  //   ["upper-austria"]: "#0092CF",
  //   ["vienna"]: "#66BCE2",
  //   ["central-switzerland"]: "#662D49",
  //   ["eastern-switzerland"]: "#9D0638"
  // };
  const regionColors = {
    ["Upper-AU"]: "#0092CF",
    ["Vienna"]: "#66BCE2",
    ["Central-SW"]: "#9D0638",
    ["Eastern-SW"]: "#662D49"
  };

  const renderMetric = (
    title: string,
    statusData: { [key: string]: number | undefined },
    total: number,
    unit: string = "",
    simpleMetrics: {
      label: string;
      value: number;
      unit: string;
      width?: string;
    }[],
    width?: number,
    showTotalString?: boolean,
    showUnitInLegend?: boolean,
    sortDescending?: boolean,
    formatLargeNumbers?: boolean, // to format big numbers 1234567 -> 1,234,567
    formatter?
    // colorSource?: "status" | "flavor",
  ) => {
    const colorPalette = regionColors;

    const dataChart = Object.entries(statusData)
      .filter(([, count]) => count !== undefined)
      .map(([status, count]) => ({
        label: status,
        value: count as number,
        color: colorPalette[status as keyof typeof colorPalette]
      }));

    return (
      <PieChartMetric
        title={title}
        dataChart={dataChart}
        total={total}
        unit={unit}
        simpleMetrics={simpleMetrics}
        width={width}
        showTotalString={showTotalString}
        showUnitInLegend={showUnitInLegend}
        sortDescending={sortDescending}
        formatLargeNumbers={formatLargeNumbers}
        formatter={formatter}
      />
    );
  };

  const tabContent = [
    <>
      <s.MetricsContainerBig variant={"outlined"}>
        {!s3StorageMetricsReportFormatted ? (
          <Loader text={"Loading data..."} />
        ) : (
          <React.Fragment>
            <s.TitleRegion component="h4">summary metrics</s.TitleRegion>
            <s.MetricRowContainer>
              {/* <s.MetricContainer>
                {renderMetric(
                  "Buckets",
                  s3StorageOverviewReportMetrics?.statistics_by_buckets || {},
                  s3StorageOverviewReportMetrics?.total_buckets,
                  "",
                  [],
                  355, // width
                  false, // showTotalString
                  false, // showUnitInLegend
                  true // sortDescending
                )}
              </s.MetricContainer> */}
              <s.MetricContainer>
                {renderMetric(
                  "Size",
                  s3StorageMetricsReportFormatted?.statistics_by_size || {},
                  s3StorageMetricsReportFormatted?.total_size,
                  "",
                  [],
                  355, // width
                  true, // showTotalString
                  true, // showUnitInLegend
                  true, // sortDescending
                  false, // formatLargeNumbers
                  formatMemorySizeBytes // formatter
                )}
              </s.MetricContainer>
              <s.MetricContainer>
                {renderMetric(
                  "Objects",
                  s3StorageMetricsReportFormatted?.statistics_by_objects || {},
                  s3StorageMetricsReportFormatted?.total_objects,
                  "",
                  [],
                  355, // width
                  true, // showTotalString
                  false, // showUnitInLegend
                  true, // sortDescending,
                  true // formatLargeNumbers
                )}
              </s.MetricContainer>
            </s.MetricRowContainer>
          </React.Fragment>
        )}
      </s.MetricsContainerBig>
      {regionS3StorageMetricsReport && (
        <s.MetricsContainerBig variant={"outlined"}>
          <React.Fragment>
            <s.TitleRegion component="h4">region metrics</s.TitleRegion>
            {regionS3StorageMetricsReport?.map((data, index) => (
              <s.Container key={index}>
                <s.MetricTitleContainer>
                  <s.MetricTitle>{data.region}</s.MetricTitle>
                </s.MetricTitleContainer>
                {/* <s.TitleRegion component="h4">{data.region}</s.TitleRegion> */}
                <s.MetricRowContainer>
                  {renderSimpleMetric("Size", data.region_total_size, "")}
                  {renderSimpleMetric("Buckets", data.region_total_buckets, "")}
                  {renderSimpleMetric(
                    "Objects",
                    data.region_total_objects.toLocaleString(),
                    ""
                  )}
                </s.MetricRowContainer>
              </s.Container>
            ))}
          </React.Fragment>
        </s.MetricsContainerBig>
      )}
    </>,
    <>
      <Table
        isSearchEnabled={true}
        isSortingEnabled={true}
        columns={tableColumns}
        isLoading={!tableS3StorageTopUsersReport}
        rows={tableS3StorageTopUsersReport || []}
        totalRow={tableS3StorageTopUsersReportTotal || undefined}
        itemLink={{
          column: "email",
          getURL: generateS3StorageTopUsersTableItemURL
        }}
        toolbarItems={
          <>
            <Box style={{ display: "flex", flex: 1 }}>
              <CustomSelect
                label={"Region"}
                options={tableRegionsOptions}
                defaultValue={tableRegionsOptions[0].value}
                value={showOnlyRegion}
                onChange={handleChangeRegionFilter}
                margin={"0 15px 0 0"}
              />
              <CustomSelect
                label={"Attribute"}
                options={S3_GET_REPORT_ATTR_OPTIONS}
                defaultValue={S3_GET_REPORT_ATTR_OPTIONS[0].value}
                value={showOnlyAttr}
                onChange={handleChangeAttrFilter}
                margin={"0 15px 0 0"}
                width="100px"
              />
              <CustomSelect
                label={"Top"}
                options={S3_GET_REPORT_TOP_OPTIONS}
                defaultValue={S3_GET_REPORT_TOP_OPTIONS[0].value}
                value={showOnlyTop}
                onChange={handleChangeTopFilter}
                margin={"0 15px 0 0"}
                width="100px"
              />
            </Box>
            <s.CashContainer2>
              <s.ActionsContainer>
                {s3StorageCacheDate && (
                  <Tooltip title={"Report updated at:"} placement={"top"} arrow>
                    <span>
                      <s.Description>
                        Report date: {s3StorageCacheDate}
                      </s.Description>
                    </span>
                  </Tooltip>
                )}

                <Tooltip title={"Refresh report"} placement={"top"} arrow>
                  <span>
                    <IconButton
                      onClick={handleConfirmUpdateCacheData}
                      color={"inherit"}
                      disabled={
                        isRefreshButtonDisabled || !tableS3StorageTopUsersReport
                      }
                    >
                      <s.RotatingRefreshIcon
                        $isSpinning={isRefreshButtonDisabled}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </s.ActionsContainer>
            </s.CashContainer2>
          </>
        }
      />
    </>
  ];

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.SummaryContainer>
        <s.Title variant={"h4"} component={"h2"}>
          {title}
        </s.Title>
      </s.SummaryContainer>
      <s.TabsContainer>
        <Tabs value={activeTabIndex} onChange={handleChangeTab}>
          {Object.values(S3_MONITORING_TAB_TITLES).map((tab) => (
            <Tab key={tab.title} label={tab.title} disabled={tab.disabled} />
          ))}
        </Tabs>
      </s.TabsContainer>
      {tabContent[activeTabIndex]}
    </>
  );
};
