import { format, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { DATE_FORMATS } from "../constants";

export const formatDate = (date: Date, dateFormat: DATE_FORMATS): string =>
  format(date, dateFormat);

export const formatUTCDate = (
  date: string | Date,
  dateFormat: string
): string => {
  const parsedDate = typeof date === "string" ? parseISO(date) : date;
  return formatInTimeZone(parsedDate, "UTC", dateFormat);
};
