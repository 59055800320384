import * as auth from "modules/auth/reducers";
import * as billing from "modules/billing/reducers";
import * as enterprises from "modules/enterprises/reducers";
import * as notifications from "modules/notifications/reducers";
import * as polling from "modules/polling/reducers";
import * as users from "modules/users/reducers";
import * as projects from "modules/projects/reducers";
import * as networking from "modules/networking/reducers";
import * as instances from "modules/instances/reducers";
import * as backups from "modules/backups/reducers";
import * as volumes from "modules/volumes/reducers";
import * as s3_storage from "modules/s3_storage/reducers";
import * as monitoring from "modules/monitoring/reducers";
import { combineReducers } from "redux";

export type RootReducer = {
  auth: auth.Reducer;
  notifications: notifications.Reducer;
  polling: polling.Reducer;
  billing: billing.Reducer;
  users: users.Reducer;
  enterprises: enterprises.Reducer;
  projects: projects.Reducer;
  networking: networking.Reducer;
  instances: instances.Reducer;
  backups: backups.Reducer;
  volumes: volumes.Reducer;
  s3_storage: s3_storage.Reducer;
  monitoring: monitoring.Reducer;
};

export const rootReducer = combineReducers<RootReducer>({
  auth: auth.reducer,
  notifications: notifications.reducer,
  polling: polling.reducer,
  billing: billing.reducer,
  users: users.reducer,
  enterprises: enterprises.reducer,
  projects: projects.reducer,
  networking: networking.reducer,
  instances: instances.reducer,
  volumes: volumes.reducer,
  s3_storage: s3_storage.reducer,
  backups: backups.reducer,
  monitoring: monitoring.reducer
});
