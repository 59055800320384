export enum BACKUPS_STATUSES {
  AVAILABLE = "available",
  CANCELLED = "cancelled",
  ERROR = "error",
  EXECUTING = "executing"
}

export enum BACKUPS_TYPES {
  FULL = "full",
  INCREMENTAL = "incremental"
}

// all snapshots and with overview
export type BackupsSnapshots = {
  [region: string]: {
    snapshots: BackupsSnapshot[];
  };
};

export type BackupsSnapshotsCached = {
  cached_at: string;
  total_servers_count: number;
  protected_servers_count: number;
  total_snapshots_count: number;
  total_snapshots_size: number;
  statistics_by_statuses: {
    [key: string]: number;
    // [key in BACKUPS_STATUSES]?: number;
  };
  statistics_by_type: {
    [key in BACKUPS_TYPES]?: number;
  };
  snapshots_by_region: {
    [region: string]: {
      region: string;
      cached_at: string;
      total_servers_count: number;
      protected_servers_count: number;
      total_snapshots_count: number;
      total_snapshots_size: number;
      statistics_by_statuses: {
        // [key in BACKUPS_STATUSES]?: number;
        [key: string]: number;
      };
      statistics_by_type: {
        [key in BACKUPS_TYPES]?: number;
      };
      snapshots: BackupsSnapshot[];
    };
  };
  // total_count: number;
  // offset: number;
  // limit: number;
};

export type BackupsSnapshot = {
  id: string;
  created_at: string;
  status: string;
  snapshot_type: string;
  workload_id: string;
  name: string;
  description: string;
  host: string;
  size: number;
  restore_size: number;
  uploaded_size: number;
  progress_percent: number;
  progress_msg: string;
  warning_msg: string;
  error_msg: string;
  time_taken: number;
  project_id: string;
  project_name: string;
  organization_id: string;
  user_id: string;
  user_name: string;
  email: string;
  region: string;
  // details: BackupsSnapshotDetails;
};

// details snapshot
export type BackupsSnapshotDetails = {
  id: string;
  created_at: string;
  updated_at?: string;
  finished_at?: string;
  status: string;
  snapshot_type: string;
  instances: {
    id: string;
    name: string;
    status: string;
    metadata?: any;
    flavor: {
      vcpus: string; //"1";
      ram: string; // "512";
      disk: string; //"1";
      ephemeral: string; //"0";
      id?: string;
    };
    security_group: {
      name: string; //"default";
      security_group_type: string; //"neutron";
    }[];
    nics: {
      mac_address: string;
      ip_address: string;
      network: {
        id: string;
        name: string;
        cidr?: string;
        network_type: string;
        subnet?: {
          id: string;
          name: string;
          cidr?: string;
          ip_version: number;
          gateway_ip: string;
        };
      };
    }[];
    vdisks?: {
      label?: string | null;
      resource_id: string;
      restore_size: number; // 1073741824;
      vm_id: string;
      volume_id: string;
      volume_name: string;
      volume_size: string;
      volume_type: string; //"iscsi";
      volume_mountpoint: string; //"/dev/vda";
      availability_zone: string; // "nova";
      metadata?: {
        readonly: string; //"False";
        attached_mode: string; //"rw";
      };
    }[];
  }[];
  name: string;
  description: string;
  host: string;
  size?: number; //44171264;
  restore_size?: number;
  uploaded_size: number;
  progress_percent?: number;
  progress_msg?: string;
  warning_msg?: string;
  error_msg?: string;
  time_taken?: number;
  pinned?: boolean;
  metadata?: {
    created_at: string;
    updated_at?: string;
    deleted_at?: string;
    deleted: boolean;
    version: string;
    id: string;
    snapshot_id: string;
    key: string;
    value: string;
  }[];
  workload_id: string;
  project_id: string;
  user_id: string;
  restores_info?: string; //"";
};

export type TableBackupsSnapshot = {
  id: string;
  name: string;
  snapshot_type: string;
  status: string;
  size: string;
  time_taken: string;
  restore_size: string;
  uploaded_size: string;
  progress_percent: string;
  progress_msg?: string;
  warning_msg?: string;
  error_msg?: string;
  msg?: string;
  workload_id: string;
  project_id: string;
  project_name: string;
  organization_id: string;
  user_id: string;
  user_name: string;
  email: string;
  region: string;
  created_at: string;
  created_at_timestamp: number; // for sorting in selector
};

export type BackupsSnapshotsOverviewData = {
  region: string;
  total_servers_count: number;
  protected_servers_count: number;
  total_snapshots_count: number;
  total_snapshots_size: string;
  total_full_snapshots_count: number;
  total_incremental_snapshots_count: number;
  statistics_by_statuses: {
    [key: string]: number;
    // [key in BACKUPS_STATUSES]?: number;
  };
  statistics_by_type: {
    [key in BACKUPS_TYPES]?: number;
  };
};

export type GetAllBackupsSnapshotsParams = {
  pollingId?: string;
  regions?: string;
  status?: string;
};

export type GetAllBackupsSnapshotsResponse = BackupsSnapshots;

export type GetErroredBackupsSnapshotsParams = {
  pollingId?: string;
  regions?: string;
};

export type GetErroredBackupsSnapshotsResponse = BackupsSnapshots;

export type GetAllCachedBackupsSnapshotsParams = {
  pollingId?: string;
  regions?: string;
  status?: string;
  // filter?: string;
  // search?: string;
  // offset?: number;
  // limit?: number;
  // show?: string;
  // sort?: string;
  // order?: string;
};

export type GetAllCachedBackupsSnapshotsResponse = BackupsSnapshotsCached;

export type UpdateCacheBackupsSnapshotsResponse = { message: string };
