import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { S3StorageMetricsReport, S3StorageTopUsersReport } from "./types";

export type Reducer = {
  s3StorageMetricsReport: S3StorageMetricsReport | null;
  s3StorageTopUsersReport: S3StorageTopUsersReport | null;
  isS3StorageMetricsReportLoading: boolean;
  isS3StorageTopUsersReportLoading: boolean;
  isS3StorageCacheUpdating: boolean;
};

const initialState: Reducer = {
  s3StorageMetricsReport: null,
  s3StorageTopUsersReport: null,
  isS3StorageMetricsReportLoading: false,
  isS3StorageTopUsersReportLoading: false,
  isS3StorageCacheUpdating: false
};

export const reducer = reducerWithInitialState(initialState)
  // Metrics
  .case(
    actions.getS3StorageMetricsReport.started,
    (state): Reducer => ({
      ...state,
      isS3StorageMetricsReportLoading: true
    })
  )
  .case(
    actions.getS3StorageMetricsReport.done,
    (state, payload): Reducer => ({
      ...state,
      s3StorageMetricsReport: payload.result,
      isS3StorageMetricsReportLoading: false
    })
  )
  .case(
    actions.getS3StorageMetricsReport.failed,
    (state): Reducer => ({
      ...state,
      isS3StorageMetricsReportLoading: false
    })
  )

  // Top USers
  .case(
    actions.getS3StorageTopUsersReport.started,
    (state): Reducer => ({
      ...state,
      isS3StorageTopUsersReportLoading: true
    })
  )
  .case(
    actions.getS3StorageTopUsersReport.done,
    (state, payload): Reducer => ({
      ...state,
      s3StorageTopUsersReport: payload.result,
      isS3StorageTopUsersReportLoading: false
    })
  )
  .case(
    actions.getS3StorageTopUsersReport.failed,
    (state): Reducer => ({
      ...state,
      isS3StorageTopUsersReportLoading: false
    })
  )

  .case(
    actions.updateCacheS3StorageReport.started,
    (state): Reducer => ({
      ...state,
      isS3StorageCacheUpdating: true
    })
  )
  .case(
    actions.updateCacheS3StorageReport.done,
    (state, payload): Reducer => ({
      ...state,
      isS3StorageCacheUpdating: false
    })
  )
  .case(
    actions.updateCacheS3StorageReport.failed,
    (state): Reducer => ({
      ...state,
      isS3StorageCacheUpdating: false
    })
  )

  .case(
    actions.clearS3StorageTopUsersList,
    (state): Reducer => ({
      ...state,
      s3StorageTopUsersReport: initialState.s3StorageTopUsersReport
    })
  )

  .case(actions.clear, (): Reducer => initialState);
