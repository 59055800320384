export enum INSTANCE_STATUSES {
  ACTIVE = "ACTIVE",
  BUILD = "BUILD",
  DELETED = "DELETED",
  ERROR = "ERROR",
  HARD_REBOOT = "HARD_REBOOT",
  MIGRATING = "MIGRATING",
  PASSWORD = "PASSWORD",
  PAUSED = "PAUSED",
  REBOOT = "REBOOT",
  REBUILD = "REBUILD",
  RESCUE = "RESCUE",
  RESIZE = "RESIZE",
  REVERT_RESIZE = "REVERT_RESIZE",
  SHELVED = "SHELVED",
  SHELVED_OFFLOADED = "SHELVED_OFFLOADED",
  SHUTOFF = "SHUTOFF",
  SOFT_DELETED = "SOFT_DELETED",
  SUSPENDED = "SUSPENDED",
  UNKNOWN = "UNKNOWN",
  VERIFY_RESIZE = "VERIFY_RESIZE"
}

export enum INSTANCE_FLAVORS {
  VC_05 = "VC-05",
  VC_1 = "VC-1",
  VC_2 = "VC-2",
  VC_2x2 = "VC-2x2",
  VC_4 = "VC-4",
  VC_8 = "VC-8",
  VC_16 = "VC-16",
  VC_32 = "VC-32",
  VC_64 = "VC-64",
  VC_128 = "VC-128",
  VC_225 = "VC-225",
  GPU_H100_1 = "GPU-H100-1",
  GPU_H100_2 = "GPU-H100-2",
  GPU_H100_4 = "GPU-H100-4",
  GPU_H100_8 = "GPU-H100-8",
  GPU_L40S_1 = "GPU-L40S-1",
  GPU_L40S_2 = "GPU-L40S-2",
  GPU_L40S_4 = "GPU-L40S-4",
  VC_32_x16 = "VC-32-x16",
  GPU_H100_4_fs = "GPU-H100-4-fs",
  CPU_L40S_1_fs = "CPU-L40S-1-fs",
  M1_AMPHORA = "m1.amphora",
  VC_12_32_CARITAS_1 = "VC-12-32-caritas-1",
  VC_12_32_CARITAS_2 = "VC-12-32-caritas-2",
  C4V_16 = "C4V-16",
  C4V_32 = "C4V-32",
  VC_30x64 = "VC-30x64",
  VC_225_GPU_A100 = "VC-225-GPU-A100",
  VC_64_GPU_A100 = "VC-64-GPU-A100",
  VC_16_GPU_A100 = "VC-16-GPU-A100",
  VC_512 = "VC-512",
  VC_24_GPU_A100 = "VC-24-GPU-A100"
}

export type Instance = {
  name: string;
  id: string;
  status: INSTANCE_STATUSES;
  fault?: {
    code: number;
    created: string;
    message: string;
    details?: string;
  };
  flavor: {
    original_name: string;
    ram: number;
    vcpus: number;
    disk: number;
  };
  ips: string;
  project_id: string;
  project_name: string;
  user_name: string;
  user_id: string;
  email: string;
  region: string;
  organization_id: string;
  created: string;
};

export type TableVirtualMachine = {
  id: string;
  name: string;
  status: INSTANCE_STATUSES;
  ips: string;
  vCPUs: number;
  memory: string;
  flavor: string;
  created: string;
  project_id: string;
  project_name: string;
  user_name: string;
  email: string;
  user_id: string;
  region: string;
  organization_id: string;
  faultMessage: string;
  created_at_timestamp?: number; // for sorting in selector
};

export type GetGpuInstancesParams = {
  regions: string;
  status?: string;
  pollingId?: string;
};

export type GetGpuInstancesResponse = Instance[];

export type GetInstancesParams = {
  pollingId?: string;
  regions?: string;
  status?: string;
  filter?: string;
  search?: string;
  // offset?: number;
  // limit?: number;
  // show?: string;
  // sort?: string;
  // order?: string;
};

export type GetInstancesResponse = Instance[];

export type InstancesCached = {
  total_servers_count: number;
  gpu_servers_count: number;
  other_servers_count: number;
  cached_at: string;
  statistics_by_statuses: {
    [key in INSTANCE_STATUSES]?: number;
  };
  statistics_by_flavors: {
    [key: string]: number;
  };
  servers_by_regions: {
    [region: string]: {
      cached_at: string;
      total_servers_count: number;
      gpu_servers_count: number;
      other_servers_count: number;
      statistics_by_statuses: {
        [key in INSTANCE_STATUSES]?: number;
      };
      statistics_by_flavors: {
        [key: string]: number;
      };
      servers: Instance[];
    };
  };
  // total_count: number;
  // offset: number;
  // limit: number;
};

export type InstancesOverviewData = {
  region: string;
  total_servers_count: number;
  gpu_servers_count: number;
  other_servers_count: number;
  statistics_by_statuses: {
    [key in INSTANCE_STATUSES]?: number;
  };
  statistics_by_flavors: {
    [key: string]: number;
  };
};

export type RegionCacheData = {
  region: string;
  cached_at: string;
};

export type GetInstancesCachedParams = {
  regions?: string;
  status?: string;
  filter?: string;
  search?: string;
  // offset?: number;
  // limit?: number;
  // show?: string;
  // sort?: string;
  // order?: string;
};

export type GetInstancesCachedResponse = InstancesCached;

export type UpdateCacheInstancesResponse = { message: string };

export type OrphanedInstancesCached = {
  cached_at: string;
  abandoned_servers: Instance[];
};

export type TableOrphanedVirtualMachine = {
  id: string;
  name: string;
  status: string;
  ips: string;
  vCPUs: number;
  memory: string;
  flavor: string;
  created: string;
  project_id: string;
  project_name: string;
  user_name: string;
  email: string;
  user_id: string;
  region: string;
  organization_id: string;
  created_at_timestamp?: number; // for sorting in selector
};

export type GetOrphanedInstancesCachedParams = {
  regions?: string;
  status?: string;
  filter?: string;
};

export type GetOrphanedInstancesCachedResponse = OrphanedInstancesCached;
